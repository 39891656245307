/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

// Elements
import ContentDefault from 'components/elements/ContentDefault'
import ButtonDefault from 'components/elements/ButtonDefault'

const Title = styled.h2`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.xl};
  font-weight: ${props => props.theme.font.weight.l};
  text-transform: uppercase;
  letter-spacing: 1px;
`

const Table = styled.div`
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
`

const Icon = styled(BackgroundImage)`
  display: block;
  margin: 0 auto;
  width: 35px;
  height: 35px;
  background-size: contain;
`

const People = styled.h3`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.l};
  font-weight: ${props => props.theme.font.weight.sm};
  background-color: ${props => props.theme.color.dark};
  color: ${props => props.theme.color.text.light};
  margin: 0;
  padding-bottom: 0;
  text-align: center;
  letter-spacing: 1px;

  & strong {
    font-size: ${props => props.theme.font.size.xl};
    font-weight: ${props => props.theme.font.weight.l};
  }

  & p {
    margin: 0;
  }
`

const Content = styled(ContentDefault)`
  font-size: ${props => props.theme.font.size.sm};
`

const ArrangementPrices = ({ className, ids }) => {
  const {
    allWordpressWpArrangement: { edges: items },
  } = useStaticQuery(graphql`
    {
      allWordpressWpArrangement(
        sort: { fields: acf___sortnumber, order: ASC }
      ) {
        edges {
          node {
            wpId: wordpress_id
            acf {
              icon {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 35) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              title
              description

              buttonText: button_text
              buttonLocation: button_location
            }
          }
        }
      }
    }
  `)

  let arragementPosts = items

  if (ids && ids.length > 0) {
    arragementPosts = items.filter(({ node: { wpId } }) => ids.indexOf(wpId) !== -1)
  }

  return (
    <section className={className}>
      <div className="container">
        <div className="text-center">
          <Title>
            Arrangementen
          </Title>
          <p>
            Om het gemakkelijk te maken hebben we een aantal arrangementen samengesteld.
            <br />
            Deze kunnen volledig naar wens worden aangepast.
          </p>
        </div>

        <div className="row mt-5">
          {arragementPosts.map(
            ({
              node: {
                wpId,
                acf: { icon, title, description, buttonText, buttonLocation },
              },
            }) => (
              <div key={wpId} className={`${arragementPosts.length === 2 ? 'col-md-6' : 'col-lg-4 col-md-6'} px-4 mb-5`}>
                <Table className="h-100">
                  <div className="px-3 py-4">
                    <Icon fluid={icon.localFile.childImageSharp.fluid} />
                  </div>
                  <People className="py-2 px-3">
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  </People>
                  <div className="px-3 py-4">
                    <Content content={description} />

                    <ButtonDefault
                      to={buttonLocation}
                      className="w-100 text-center mt-4"
                    >
                      {buttonText}
                    </ButtonDefault>
                  </div>
                </Table>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  )
}

export default ArrangementPrices
