/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import MoreInformation from 'components/MoreInformation'
import ArrangementPrices from 'components/ArrangementPrices'
import Gallery from 'components/Gallery'

// Elements
import ContentDefault from 'components/elements/ContentDefault'
import HeroDefault from 'components/elements/HeroDefault'
import TitleSub from 'components/elements/TitleSub'
import BrandTitle from 'components/elements/BrandTitle'
import ContainerSmall from 'components/elements/ContainerSmall'

const IntroImage = styled(BackgroundImage)`
  background-size: contain;
  background-position: top;

  @media (min-width: 992px) {
    width: 550px;
    height: 400px;
  }

  @media (max-width: 991px) {
    width: 450px;
    height: 350px;
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        introImage: intro_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 550) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        introDescription: intro_description
        contactRelation: contact_relation
        galleryImages: gallery_images {
          wpId: wordpress_id
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
        }
        arrangementen
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PartyTemplate = ({
  data: {
    page: { title, path, yoastMeta, acf },
  },
}) => {
  return (
    <Layout headerTop>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.backgroundImage.localFile.childImageSharp.meta.src}
        article
      />
      <HeroDefault title={title} image={acf.backgroundImage} />
      <div className="container">
        <section className="pb-5">
          <div className="row">
            <div className="col-lg-6 ">
              <IntroImage
                fluid={acf.introImage.localFile.childImageSharp.fluid}
              />
            </div>
            <div className="col-lg-6 pt-3 pt-lg-5 pl-5">
              <TitleSub right>{title}</TitleSub>
              <BrandTitle />
              <ContentDefault className="mt-5" content={acf.introDescription} />
            </div>
          </div>
        </section>
      </div>
      <section>
        <MoreInformation id={acf.contactRelation} className="mt-2 mb-5" />
      </section>
      <section>
        <ContainerSmall className="mt-5 py-5">
          <ArrangementPrices ids={acf.arrangementen} className="mb-lg-5 pb-5" />
        </ContainerSmall>
      </section>
      {acf.galleryImages && (
        <section className="mb-5 pb-5">
          <Gallery className="mb-5" images={acf.galleryImages} />
        </section>
      )}
    </Layout>
  )
}

export default PartyTemplate
